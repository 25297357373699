import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Permalink from '@algbra/ui/components/Permalink/Permalink'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import React from 'react'
import PageLayout from '../components/PageLayout/PageLayout'
import { Helmet } from 'react-helmet'
import styles from './404.module.scss'

const NotFoundPage = () => (
  <PageLayout>
    <Container>
      <Helmet>
        <title>Page not found - Algbra</title>
      </Helmet>
      <Whitespace type="xl">
        <div className={styles.container}>
          <Heading type="lg">Page not found</Heading>
          <Permalink to="/" className={styles.link}>
            Return to homepage
          </Permalink>
        </div>
      </Whitespace>
    </Container>
  </PageLayout>
)

export default NotFoundPage
